import { render, staticRenderFns } from "./job-form-page.vue?vue&type=template&id=649e35ac&scoped=true&"
import script from "./job-form-page.vue?vue&type=script&lang=js&"
export * from "./job-form-page.vue?vue&type=script&lang=js&"
import style0 from "./job-form-page.vue?vue&type=style&index=0&id=649e35ac&prod&lang=scss&scoped=true&"
import style1 from "./job-form-page.vue?vue&type=style&index=1&id=649e35ac&prod&lang=scss&scoped=true&"
import style2 from "./job-form-page.vue?vue&type=style&index=2&id=649e35ac&prod&lang=scss&"
import style3 from "./job-form-page.vue?vue&type=style&index=3&id=649e35ac&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649e35ac",
  null
  
)

export default component.exports
import {QIcon,QBtn,QForm,QInput,QSeparator,QToggle,QPopupProxy,QDate,QDialog,QCard,QCardSection,QSpinnerIos,QField,QCheckbox,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon,QBtn,QForm,QInput,QSeparator,QToggle,QPopupProxy,QDate,QDialog,QCard,QCardSection,QSpinnerIos,QField,QCheckbox})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
