
import { mapActions, mapGetters } from "vuex";
// import { i18n } from '@/i18n';
import i18n from "@/vueI18n";
import firebase from "firebase/app";
import "firebase/firestore";
// var today = new Date();
// var dd = String(today.getDate()).padStart(2, '0');
// var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
// var yyyy = today.getFullYear();
import AddDialog from "@/modules/services/components/add-dialog.vue";
import SuccessDialog from "@/modules/services/components/success-dialog.vue";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

// import { VueDatePicker } from '@mathieustan/vue-datepicker';
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import moment from "moment";

let timeStamp = Date.now();
let formattedDate = moment(timeStamp).format("DD-MM-YYYY");
// import { ref } from 'vue'
export default {
  name: "app-job-form",
  // props:['jobCreator'],

  //     setup () {
  //     // const date = ref('2019/03/01')
  //     const date = Date.now()
  //     // const proxyDate = ref('2019/03/01')

  //     return {
  //       date,
  //     //   proxyDate,

  //     //   updateProxy () {
  //     //     proxyDate.value = date.value
  //     //   },

  //     //   save () {
  //     //     date.value = proxyDate.value
  //     //   }
  //     }
  //   },

  data() {
    return {
      // v-models
      jobCreator: "individual",
      companyName: "",
      workplaceModel: {},
      positionModel: {},
      cityModel: {},
      regionModel: {},
      deactivationModel: {},
      priceRangeBegin: null,
      priceRangeEnd: null,
      perHour: "",
      description: "",
      address: "",
      phoneNumber: "",
      active: true,
      deactivate: false,
      time: "",
      emailAddress: "",
      validEmail: false,
      validPhone: false,
      validCompanyName: false,
      validWorkplaceName: false,
      validPositionName: false,
      validCityName: false,
      validSalaryFrom: false,
      validSalaryTo: false,
      validAddress: false,
      validDescription: false,
      validDate: false,
      // enableSubmit: false,
      // currentUser:true,

      negotiable: true,
      perMonth: "",
      // date:yyyy + '/' + mm + '/' + dd  ,
      // date: formattedDate,
      date: moment(Date.now()).format("DD-MM-YYYY"),
      data: {
        start_date: formattedDate,
      },

      // date: ref('1/8/2021'),
      // locale: { lang: 'ar', weekDays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'] },
      color: "#bf507a",
      qDateProxy: false,
      workplaceList: false,
      position: false,
      cityList: false,
      regionList: false,
      publishDialog: false,
      timeDialog: false,
      workplaceChoice: "",
      // workplaces:[],
      // positions:[],
      // cities:[],
      // regions:[],
      cityRegions: [],

      workplaceName: "",
      cityName: "",
      regionName: "",
      positionName: "",
      deactivationName: "",
      views: 0,
      viewers: {},
      createdBy: null,
      createdAt: null,
      hiring: true,

      countryCode: "+20",
      countryName: "EG",
      isValid: false,
      errorMessage: "",
      phoneLenght: 8,
      phoneMask: "#########",
      TRansObject: {
        countrySelectorLabel: "Code Country",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone Number",
        example: "Example :",
        countryCallingCode: "+20",
      },
      TRansObjectAr: {
        countrySelectorLabel: "كود البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الجوال",
        example: "مثال :",
      },
      addedJobInMonth: 0,
      jobMaxCount: undefined,
      successMsgTitle: "",
      successMsgDesc: "",
      edit: false,
    };
  },
  computed: {
    lang() {
      // debugger
      return i18n.locale;
    },
    enableSubmit() {
      // && this.validSalaryFrom && this.validSalaryTo
      // && this.validPhone
      // && this.validAddress
      if (
        this.validCompanyName &&
        this.validWorkplaceName &&
        this.validPositionName &&
        this.validCityName &&
        this.validDescription &&
        this.validPhone
      ) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      saveLoading: "jobs/form/saveLoading",
      recordCreated: "jobs/form/recordCreated",
      workplaces: "jobs/list/workplaces",
      jobPositions: "jobs/list/jobPositions",
      cities: "jobs/list/cities",
      regions: "jobs/list/regions",
      deactivationReasons: "jobs/list/deactivationReasons",
      record: "jobs/view/record",
      city: "jobs/view/city",
      region: "jobs/view/region",
      jobPosition: "jobs/view/jobPosition",
      // deactivationReason: 'jobs/list/deactivationReason',
      workplace: "jobs/view/workplace",
      mountedCityTable: "jobs/list/mountedCityTable",
      mountedPositionTable: "jobs/list/mountedPositionTable",
      mountedWorkplaceTable: "jobs/list/mountedWorkplaceTable",
      mountedDeactivationReasonTable:
        "jobs/list/mountedDeactivationReasonTable",
      recordMounted: "jobs/view/recordMounted",
      userListener: "auth/userListener",
    }),
    isRTL() {
      return i18n.locale == "ar";
    },
  },
  watch: {
    lang() {
      document.title =
        this.i18n("app.title") + " | " + this.i18n("drugDeal.services.jobs");
    },
    // perHour(val){
    // },
    async record(newvalue) {
      if (newvalue) {
        // await this.doFindCity({cityId:newvalue.cityId,regionId:newvalue.regionId})
        // await this.doFindJobPosition({id:newvalue.positionId})
        // await this.doFindWorkplace({id:newvalue.workplaceId})
        this.jobCreator = newvalue.type;
        this.companyName = newvalue.name;
        this.address = newvalue.address;
        this.description = newvalue.description;
        this.phoneNumber = newvalue.phoneNumber;
        this.validPhone = true;
        this.countryCode = newvalue.countryCode ? newvalue.countryCode : "+20";
        this.countryName = newvalue.countryName ? newvalue.countryName : "EG";
        this.active = newvalue.isActive;
        this.emailAddress = newvalue.emailAddress;
        this.negotiable = newvalue.salary.isNegotiable;
        this.priceRangeBegin = newvalue.salary.from;
        this.priceRangeEnd = newvalue.salary.to;
        this.positionModel["id"] = newvalue.positionId;
        this.workplaceModel["id"] = newvalue.workplaceId;
        this.cityModel["id"] = newvalue.cityId;
        this.deactivationModel["id"] = newvalue.deactivationReasonId;
        if (newvalue.regionId) {
          this.regionModel["id"] = newvalue.regionId;
        }
        this.createdAt = newvalue.createdAt;
        this.createdBy = newvalue.createdBy;
        this.viewers = newvalue.viewers;

        this.cityRegions = this.regions[newvalue.cityId];
        if (newvalue.per === "Month") {
          this.perMonth = true;
        } else {
          this.perMonth = false;
        }
      }
    },
    // deactivationReasons(newvalue){
    //     console.log('deactivationReasons',newvalue);
    // },
    deactivationReason(newvalue) {
      if (newvalue) {
        this.deactivationModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.deactivationName = newvalue.name.ar;
        } else {
          this.deactivationName = newvalue.name.en;
        }
      }
    },
    jobPosition(newvalue) {
      if (newvalue) {
        this.positionModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.positionName = newvalue.name.ar;
        } else {
          this.positionName = newvalue.name.en;
        }
      }
    },
    workplace(newvalue) {
      if (newvalue) {
        this.workplaceModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.workplaceName = newvalue.name.ar;
        } else {
          this.workplaceName = newvalue.name.en;
        }
      }
    },
    city(newvalue) {
      if (newvalue) {
        this.cityModel["name"] = newvalue.name;
        this.cityName = newvalue.name.ar;
        if (this.isRTL) {
          this.cityName = newvalue.name.ar;
        } else {
          this.cityName = newvalue.name.en;
        }
      }
    },
    region(newvalue) {
      if (newvalue) {
        // if(this.regionModel){
        this.regionModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.regionName = newvalue.name.ar;
        } else {
          this.regionName = newvalue.name.en;
        }
        // }
      }
    },
    isRTL(newvalue) {
      if (newvalue) {
        if (Object.keys(this.cityModel).length != 0) {
          this.cityName = this.cityModel["name"]["ar"];
        }
        if (Object.keys(this.regionModel).length != 0) {
          this.regionName = this.regionModel["name"]["ar"];
        }
        if (Object.keys(this.workplaceModel).length != 0) {
          this.workplaceName = this.workplaceModel.name.ar;
        }
        if (Object.keys(this.positionModel).length != 0) {
          this.positionName = this.positionModel.name.ar;
        }
        if (Object.keys(this.deactivationModel).length != 0) {
          this.deactivationName = this.deactivationModel.name.ar;
        }
        if (this.city) {
          this.cityName = this.city.name.ar;
        }
        if (this.region) {
          this.regionName = this.region.name.ar;
        }
        if (this.workplace) {
          this.workplaceName = this.workplace.name.ar;
        }
        if (this.jobPosition) {
          this.positionName = this.jobPosition.name.ar;
        }
        if (this.deactivationReason) {
          this.deactivationName = this.deactivationReason.name.ar;
        }
      } else {
        // this.cityName = this.cityModel['name']['en']
        // this.regionName = this.regionModel['name']['en']
        if (Object.keys(this.cityModel).length != 0) {
          this.cityName = this.cityModel["name"]["en"];
        }
        if (Object.keys(this.regionModel).length != 0) {
          this.regionName = this.regionModel["name"]["en"];
        }
        if (Object.keys(this.workplaceModel).length != 0) {
          this.workplaceName = this.workplaceModel.name.en;
        }
        if (Object.keys(this.positionModel).length != 0) {
          this.positionName = this.positionModel.name.en;
        }
        if (Object.keys(this.deactivationModel).length != 0) {
          this.deactivationName = this.deactivationModel.name.en;
        }
        if (this.city) {
          this.cityName = this.city.name.en;
        }
        if (this.region) {
          this.regionName = this.region.name.en;
        }
        if (this.workplace) {
          this.workplaceName = this.workplace.name.en;
        }
        if (this.jobPosition) {
          this.positionName = this.jobPosition.name.en;
        }
        if (this.deactivationReason) {
          this.deactivationName = this.deactivationReason.name.en;
        }
      }
    },
  },
  methods: {
    inputDate() {
      this.date = this.data.start_date;
      this.$refs.qDateProxy.hide();
      // debugger
    },

    // async setID(){
    //     // await firebase.firestore().collection(`city/Cairo/regions`).doc('Cairo').update({
    //     //     id: 'Cairo'
    //     // })
    // const citiesCollection = await firebase.firestore().collection("city").get()
    // citiesCollection.docs.forEach(async (doc)=>{
    //   let city =  doc.data()
    //   city.id = doc.id
    //   const regions = await firebase.firestore().collection(`city/${city.id}/regions`).get()
    //   regions.docs.forEach(doc => {
    //     firebase.firestore().collection(`city/${city.id}/regions`).doc(doc.id).update({
    //         id: doc.id
    //     })
    //   })
    // })
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (re.test(String(email).toLowerCase())) {
        this.validEmail = true;
        return true;
      } else {
        this.validEmail = false;
        return false;
      }
    },
    onPhoneUpdate(data) {
      // debugger
      // debugger
      this.countryCode = "+" + data.countryCallingCode;
      // debugger
      this.countryName = data.countryCode;
      this.isValid = data.isValid;
      this.errorMessage = "";

      this.validPhone = data.isValid;
      // this.validatePhone(data.formattedNumber)
    },
    // validatePhone(val){
    //     const phoneno = /^\d{11}$/.test(val)
    //     if (phoneno) {
    //         this.validPhone = true
    //         return true;
    //     }
    //     else {
    //         this.validPhone = false
    //         return false;
    //     }
    //     },
    validateCompanyName(val) {
      if (val.length > 0) {
        this.validCompanyName = true;
        return true;
      } else {
        this.validCompanyName = false;
        return false;
      }
    },
    validateWorkplaceName(val) {
      if (val.length > 0) {
        this.validWorkplaceName = true;
        return true;
      } else {
        this.validWorkplaceName = false;
        return false;
      }
    },
    validatePositionName(val) {
      if (val.length > 0) {
        this.validPositionName = true;
        return true;
      } else {
        this.validPositionName = false;
        return false;
      }
    },
    validateCityName(val) {
      if (val.length > 0) {
        this.validCityName = true;
        return true;
      } else {
        this.validCityName = false;
        return false;
      }
    },
    validateSalaryFrom(val) {
      let isnum = /^\d+$/.test(val);
      if (isnum) {
        this.validSalaryFrom = true;
        return true;
      } else {
        this.validSalaryFrom = false;
        return false;
      }
    },
    validateSalaryTo(val) {
      let isnum = /^\d+$/.test(val);
      if (isnum) {
        this.validSalaryTo = true;
        return true;
      } else {
        this.validSalaryTo = false;
        return false;
      }
    },
    validateAddress(val) {
      if (val.length > 0) {
        this.validAddress = true;
        return true;
      } else {
        this.validAddress = false;
        return false;
      }
    },
    validateDescription(val) {
      if (val.length > 0) {
        this.validDescription = true;
        return true;
      } else {
        this.validDescription = false;
        return false;
      }
    },
    // validateDate(val){
    //     if(val.length > 0) {
    //         this.validDate = true
    //         return true
    //     }
    //     else {
    //         this.validDate = false
    //         return false
    //     }
    // },

    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      doCreate: "jobs/form/doCreate",
      doUpdate: "jobs/form/doUpdate",
      doFetchWorkplace: "jobs/list/doFetchWorkplace",
      doFetchJobPosition: "jobs/list/doFetchJobPosition",
      doFetchCity: "jobs/list/doFetchCity",
      doFetchRegion: "jobs/list/doFetchRegion",
      doFetchDeactivationReason: "jobs/list/doFetchDeactivationReason",
      doFind: "jobs/view/doFind",
      doFindCity: "jobs/view/doFindCity",
      doFindJobPosition: "jobs/view/doFindJobPosition",
      doFindWorkplace: "jobs/view/doFindWorkplace",
      setMountedCityTable: "jobs/list/setMountedCityTable",
      setMountedPositionTable: "jobs/list/setMountedPositionTable",
      setMountedWorkplaceTable: "jobs/list/setMountedWorkplaceTable",
      setMountedDeactivationReasonTable:
        "jobs/list/setMountedDeactivationReasonTable",
      setRecordMounted: "jobs/view/setRecordMounted",
      doFetchUser: "auth/doFetchUser",
    }),

    closeWorkplace(option) {
      this.workplaceModel = option;
      // this.workplaceName = this.workplaceModel['name']['en']
      if (this.isRTL) {
        this.workplaceName = this.workplaceModel["name"]["ar"];
      } else {
        this.workplaceName = this.workplaceModel["name"]["en"];
      }
      this.workplaceList = false;
    },
    closePosition(option) {
      this.positionModel = option;
      if (this.isRTL) {
        this.positionName = this.positionModel["name"]["ar"];
      } else {
        this.positionName = this.positionModel["name"]["en"];
      }
      this.position = false;
    },
    async closeCity(option) {
      this.cityModel = option;
      if (this.isRTL) {
        this.cityName = this.cityModel["name"]["ar"];
      } else {
        this.cityName = this.cityModel["name"]["en"];
      }
      this.cityList = false;
      if (this.cityModel) {
        this.cityRegions = this.regions[option.id.toString()];
      }
    },
    closeRegion(option) {
      this.regionModel = option;
      // this.regionName = this.regionModel['name']['en']
      if (this.isRTL) {
        this.regionName = this.regionModel["name"]["ar"];
      } else {
        this.regionName = this.regionModel["name"]["en"];
      }
      this.regionList = false;
    },
    toggleIsActive() {
      if (!this.active) {
        this.deactivate = !this.deactivate;
      } else {
        this.deactivationModel = {};
      }

      if (this.active && this.edit) {
        firebase
          .firestore()
          .collection("job")
          .doc(this.$route.params.id)
          .update({
            isActive: true,
            deactivationReasonId: null,
          });
      }
    },
    closeDeactivationReason(option) {
      this.deactivationModel = option;
      if (this.isRTL) {
        this.deactivationName = this.deactivationModel["name"]["ar"];
      } else {
        this.deactivationName = this.deactivationModel["name"]["en"];
      }
      this.deactivate = !this.deactivate;
      if (!this.active && this.edit) {
        firebase
          .firestore()
          .collection("job")
          .doc(this.$route.params.id)
          .update({
            isActive: false,
            deactivationReasonId: this.deactivationModel.id,
          });
      }
    },
    closeSuccessDialog() {
      this.publishDialog = false;
    },

    async addMoreJobs(userListener) {
      // this.setLoadingAddMoreJobs(true)
      // this.userJob = userListener.job
      this.addedJobInMonth = 0;
      var today = new Date();
      if (userListener.job || userListener.job != undefined) {
        for (const [key] of Object.entries(userListener.job)) {
          var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          if (key >= firstDay && key < lastDay) {
            this.addedJobInMonth = this.addedJobInMonth + 1;
          }
        }
        await firebase
          .database()
          .ref("setting/job")
          .on("value", (snapshot) => {
            this.jobMaxCount = snapshot.val();
            // console.log(this.addedJobInMonth);
            // console.log(this.jobMaxCount);
            this.successMsgTitle = this.i18n("drugDeal.jobs.successMsgTitle");
            if (!this.isRTL) {
              this.successMsgDesc = `(This month posts: ${this.addedJobInMonth}, Maximum posts: ${this.jobMaxCount})`;
            } else {
              this.successMsgDesc = `(هذا الشهر: ${this.addedJobInMonth}، الحد الأقصى شهرياً: ${this.jobMaxCount})`;
            }
            this.publishDialog = true;
            setTimeout(() => {
              const lang = localStorage.getItem("language") || "en";
              this.$router.push({
                name: "job-opportunities",
                params: {
                  lang,
                },
                query: {
                  page: 1,
                },
              });
            }, 3000);

            //#region
            // if(this.addedJobInMonth == this.jobMaxCount || this.addedJobInMonth > this.jobMaxCount){
            //     this.canNotAdd = true
            // }
            // else{
            //     this.canNotAdd = false
            // }
            // debugger
            // this.setLoadingAddMoreJobs(false)
            //#endregion
          });
      }
      // else{
      //     this.setLoadingAddMoreJobs(false)
      // }
    },

    async doSubmitJob() {
      // console.log(this.jobCreator);
      const payload = {
        type: this.jobCreator,
        name: this.companyName,
        workplaceId: this.workplaceModel.id,
        positionId: this.positionModel.id,
        cityId: this.cityModel.id,
        regionId: this.regionModel ? this.regionModel.id : null,
        deactivationReasonId:
          Object.keys(this.deactivationModel).length > 0
            ? this.deactivationModel.id
            : null,
        salary: {
          isNegotiable: this.negotiable,
          per: this.perMonth ? "Month" : "Hour",
          from: parseInt(this.priceRangeBegin),
          to: parseInt(this.priceRangeEnd),
        },
        status: this.hiring ? "hiring" : "hired",
        emailAddress: this.emailAddress,
        phoneNumber: this.phoneNumber,
        countryCode: this.countryCode,
        countryName: this.countryName,
        description: this.description,
        dueDate: new Date(this.date),
        // dueDate: this.date,
        address: this.address,
        postStatus: "pending",
        viewers: {},
        isActive: this.active,
      };
      // console.log(payload);
      // debugger
      if (this.$route.params.id) {
        payload.createdBy = this.createdBy;
        payload.createdAt = new Date(this.createdAt.seconds * 1000);
        payload.viewers = this.viewers;
        // console.log('update',payload)
        // debugger
        await this.doUpdate({ id: this.$route.params.id, values: payload });
      } else {
        // console.log('recordCreated payload',payload)
        await this.doCreate(payload);
        let today = Date.now();

        let user = await firebase
          .firestore()
          .collection("user")
          .doc(this.currentUser.id)
          .get();
        // console.log('user',user.data())
        let userJob = user.data().job;
        if (userJob) {
          userJob[today] = this.recordCreated.id;
          await firebase
            .firestore()
            .collection("user")
            .doc(this.currentUser.id)
            .update({
              job: userJob,
            });
        } else {
          let userJobNew = {};
          userJobNew[today] = this.recordCreated.id;
          await firebase
            .firestore()
            .collection("user")
            .doc(this.currentUser.id)
            .update({
              job: userJobNew,
            });
        }
        // console.log('user presc',userJob);
      }
      await this.addMoreJobs(this.userListener);
    },
  },
  components: {
    [AddDialog.name]: AddDialog,
    [SuccessDialog.name]: SuccessDialog,
    VuePhoneNumberInput,
    // VueDatePicker,
  },

  async mounted() {
    this.setHeaderBg("bg-shadow");
    this.$q.iconSet.field.error = "img:/images/invalid-email.png";
    // debugger

    //#region
    // this.addedJobInMonth = 0
    //     var today = new Date();
    //     console.log(this.userListener);
    //     if(!this.userListener){
    //         await this.doFetchUser(this.currentUser.id)
    //         debugger
    //     }
    //     debugger
    //     if(this.userListener.job || this.userListener.job != undefined){
    //         for (const [key] of Object.entries(this.userListener.job)) {
    //             var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    //             var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    //             if(key >= firstDay && key < lastDay){
    //                 this.addedJobInMonth = this.addedJobInMonth + 1

    //             }
    //         }
    //         await firebase.database().ref('setting/job').on('value',(snapshot) => {
    //         this.jobMaxCount = snapshot.val()
    //         console.log(this.addedJobInMonth);
    //         console.log(this.jobMaxCount);
    //         debugger
    //         if(this.addedJobInMonth >= this.jobMaxCount){
    //             // setTimeout(() => {
    //                 const lang = localStorage.getItem('language') || 'en'
    //                 this.$router.push({
    //                     name: 'job-opportunities',
    //                     params: {
    //                         lang
    //                     },
    //                     query:{
    //                         page: 1
    //                     }
    //                 })
    //             // },3000)
    //             }
    //         })

    //     }
    //#endregion
  },
  async created() {
    document.title =
      this.i18n("app.title") + " | " + this.i18n("drugDeal.services.jobs");

    let today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //As January is 0.
    var yyyy = today.getFullYear();
    let currentDate = `${yyyy}/${mm}/${dd}`;
    this.date = currentDate;

    // console.log('cities',this.cities);
    if (!this.mountedWorkplaceTable) {
      await this.doFetchWorkplace();
      this.setMountedWorkplaceTable(true);
    }
    if (!this.mountedCityTable) {
      await this.doFetchCity();
      this.setMountedCityTable(true);
    }
    if (!this.mountedPositionTable) {
      // debugger
      await this.doFetchJobPosition();
      this.setMountedPositionTable(true);
    }
    if (!this.mountedDeactivationReasonTable) {
      // console.log(this.mountedDeactivationReasonTable);
      // debugger
      await this.doFetchDeactivationReason();
      this.setMountedDeactivationReasonTable(true);
    }

    // console.log('this.$route.params.id',this.$route.params.id);
    if (this.$route.params.id && this.$route.params.edit == "edit") {
      this.edit = true;
      // if(!this.recordMounted){
      // console.log('find again');
      await this.doFind({
        currentUserId: this.currentUser.id,
        id: this.$route.params.id,
      });
      //     this.setRecordMounted(true)
      // }
    }
  },
};
