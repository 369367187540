
// import { i18n } from '@/i18n';
// import{i18n} from '@/i18n';
export default {
    name:'app-success-dialog',
    props:['publishDialog', 'successMsgTitle', 'successMsgDesc'],
    computed:{
    publish:{
        get(){
            return this.publishDialog
        },
        set(){
            this.$emit('closeSuccess')
        }
    },

},
methods:{
    i18n(key, args) {
      return this.$t(key, args);
    },
}
}
